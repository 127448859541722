<template>
  <div class='flex flex-col items-center justify-center'>
    <h1 class='text-xl font-semibold mb-8 text-gray-900'>{{ registrationDoneMessageTitle }}</h1>
    <p class='text-gray-600 mb-16'>{{ registrationDoneMessageDescription }}</p>
    <button
      @click='redirectAfterAction'
      class='border py-4 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'>
      {{ registrationDoneGoToHomeText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'RegistrationDoneMessage',
  props: ['isKorean'],
  computed: {
    registrationDoneMessageTitle () {
      return this.isKorean ? 'ICoLA2023 Welcome Reception에 등록해 주셔서 감사합니다!' : 'Successfully Registered ICoLA2023 Welcome Reception!'
    },
    registrationDoneMessageDescription () {
      return this.isKorean ? '등록 및 결제 내용은 등록완료 메일을 확인해 주시기 바랍니다.' : 'You have successfully registered ICoLA2023 welcome reception. Please check your email for the confirmation email.'
    },
    registrationDoneGoToHomeText () {
      return this.isKorean ? '홈으로 가기' : 'Go to Home'
    }
  },
  methods: {
    redirectAfterAction () {
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Main'
      this.$router.push({name: redirectRoute})
    },
  },
}
</script>
